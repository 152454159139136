import isNumber from 'lodash/isNumber'
import type {
  ApiResponse,
  Deal,
  DealAgreement,
  DealCommission,
  DealFile,
  DealPayment,
  DealPaymentInvoice,
  PaginationResponse,
} from '@/types'

export const useGetDeals = async (query: Record<string, any>) => {
  const { data, error } = await useAPIFetch<PaginationResponse>(`/finance/deals/table/default`, {
    method: 'POST',
    body: query,
  })

  if (error.value) throw createError(error.value)

  return data.value
}

export const exportDealsTable = async (filters: any, headers: string[]) => {
  const query = { ...filters, columns: headers.join(','), format: 'csv' }

  return await new Promise((resolve, reject) => {
    useAPIFetch(`/finance/deals/table/default/export`, {
      method: 'POST',
      body: query,
      onResponse({ response }) {
        if (!response.ok) reject(createError(response._data))
        const filename = response.headers.get('Content-Disposition')?.split('filename=')[1]?.replaceAll('"', '')

        resolve({
          filename,
          data: response._data,
        })
      },
      onResponseError({ response }) {
        createError(response._data)
      },
    })
  })
}

export const useGetArchivedDeals = async (query: Record<string, any>) => {
  const { data, error } = await useAPIFetch<PaginationResponse>(`/finance/deals/table/archive`, {
    method: 'POST',
    body: query,
  })

  if (error.value) throw createError(error.value)

  return data.value
}

export const exportArchiveDealsTable = async (filters: any, headers: string[]) => {
  const query = { ...filters, columns: headers.join(','), format: 'csv' }

  return await new Promise((resolve, reject) => {
    useAPIFetch(`/finance/deals/table/archive/export`, {
      method: 'POST',
      body: query,
      onResponse({ response }) {
        if (!response.ok) reject(createError(response._data))
        const filename = response.headers.get('Content-Disposition')?.split('filename=')[1]?.replaceAll('"', '')

        resolve({
          filename,
          data: response._data,
        })
      },
      onResponseError({ response }) {
        createError(response._data)
      },
    })
  })
}

export const useGetDeal = async (id: number) => {
  const { data, error } = await useAPIFetch(`/finance/deals/${id}`)
  if (error.value) throw createError(error.value)

  return (data.value as ApiResponse).data as Deal
}

export const useUpdateDeal = async (deal: Deal) => {
  const { data, error } = await useAPIFetch(`/finance/deals/${deal.id}`, { method: 'put', body: deal })
  if (error.value) throw createError(error.value)

  return (data.value as ApiResponse).data as Deal
}

export const useValidateDeal = async (deal: Deal) => {
  const { data, error } = await useAPIFetch(`/finance/deals/${deal.id}/validate`, { method: 'post', body: deal })
  if (error.value) throw createError(error.value)

  return (data.value as ApiResponse).data as Deal
}

export const useDeleteDeal = async (id: number) => {
  const { data, error } = await useAPIFetch(`/finance/deals/${id}`, { method: 'delete' })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useArchiveDeal = async (id: number) => {
  const { data, error } = await useAPIFetch(`/finance/deals/${id}/archive`, { method: 'patch' })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useCancelDeal = async (id: number, body: any) => {
  const { data, error } = await useAPIFetch(`/finance/deals/${id}/cancel`, { method: 'patch', body })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useUpdateDealState = async (id: number, statusId: number, stateId?: number) => {
  const { data, error } = await useAPIFetch(`/finance/deals/${id}/state`, {
    method: 'POST',
    body: { status_id: statusId, state_id: stateId },
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useUpdateDealApprovalStatus = async (id: number, statusId: number, typeId: number, remarks: string) => {
  const { data, error } = await useAPIFetch(`/finance/deals/${id}/approval`, {
    method: 'POST',
    body: { status_id: statusId, type_id: typeId, remark_note: remarks },
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useGetDealDownPayments = async (id: number) => {
  const { data, error } = await useAPIFetch<PaginationResponse>(`finance/deals/${id}/down-payments`)
  if (error.value) throw createError(error.value)

  return data.value
}

export const useCreateDealDownPayment = async (dealId: DealPayment['id'], downPayment: FormData) => {
  const { data, error } = await useAPIFetch(`finance/deals/${dealId}/down-payments`, {
    method: 'post',
    body: downPayment,
  })
  if (error.value) throw createError(error.value)
  return data.value as ApiResponse
}

export const useUploadDealFiles = async (dealId: number, files: DealFile[]) => {
  const formData = new FormData()

  await Promise.all(
    files.map(async (file, index) => {
      const isFileUploaded = isNumber(file.id)

      let document = file.file

      if (isFileUploaded) {
        const blob = await useDownloadDealFile(dealId, file.id as number)

        document = new File([blob], file.file_name, { type: blob.type })
      }

      formData.append(`documents[${index}][file]`, document!)
      formData.append(`documents[${index}][name]`, file.name)
      formData.append(`documents[${index}][type_id]`, String(file.type_id))
    })
  )

  const { data, error } = await useAPIFetch<ApiResponse<null>>(`finance/deals/${dealId}/documents/bulk`, {
    method: 'POST',
    body: formData,
  })

  if (error.value) throw createError(error.value)

  return data.value
}

export const useDownloadDealFile = async (dealId: number, fileId: number) => {
  const { data } = await useAPIFetch<Blob>(`finance/deals/${dealId}/files/${fileId}`)

  return data.value
}

export const useUpdateDealDownPayment = async (
  dealId: DealPayment['id'],
  downPaymentId: number,
  downPayment: FormData
) => {
  const { data, error } = await useAPIFetch(`finance/deals/${dealId}/down-payments/${downPaymentId}`, {
    method: 'patch',
    body: downPayment,
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useDeleteDealDownPayment = async (dealId: number, downPaymentId: number) => {
  const { data, error } = await useAPIFetch(`finance/deals/${dealId}/down-payments/${downPaymentId}`, {
    method: 'delete',
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useCalculateDealCommissions = async (id: number, commissions: DealCommission[]) => {
  const { data, error } = await useAPIFetch(`finance/deals/${id}/commissions/calculate`, {
    method: 'POST',
    body: { commissions },
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse<DealCommission[]>
}

export const useUpdateDealCommissions = async (id: number, commissions: DealCommission[]) => {
  const { data, error } = await useAPIFetch(`finance/deals/${id}/commissions`, {
    method: 'POST',
    body: { commissions },
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useUpdateDealCommissionPayoutStatus = async (
  id: number,
  commissionId: number,
  statusId: number,
  note?: string
) => {
  const { data, error } = await useAPIFetch(`finance/deals/${id}/commissions/${commissionId}/payout`, {
    method: 'patch',
    body: { note, status_id: statusId },
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useGetDealAgreements = async (id: number) => {
  const { data, error } = await useAPIFetch(`finance/deals/${id}/agreements`)
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useUpdateDealAgreement = async (id: number, agreement: DealAgreement) => {
  const { data, error } = await useAPIFetch(`finance/deals/${id}/agreements/${agreement.id}`, {
    method: 'patch',
    body: agreement,
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useGetDealPaymentsInvoices = async (id: number) => {
  const { data, error } = await useAPIFetch(`finance/deals/${id}/payments/invoices`)
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useCreateDealPaymentsInvoice = async (dealId: number, invoice: FormData) => {
  const { data, error } = await useAPIFetch(`finance/deals/${dealId}/payments/invoices`, {
    method: 'post',
    body: invoice,
  })
  if (error.value) throw createError(error.value)
  return data.value as ApiResponse
}

export const useUpdateDealPaymentsInvoice = async (id: number, invoiceId: number, invoice: FormData) => {
  const { data, error } = await useAPIFetch(`finance/deals/${id}/payments/invoices/${invoiceId}`, {
    method: 'patch',
    body: invoice,
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useDeleteDealPaymentsInvoice = async (dealId: number, invoiceId: number) => {
  const { data, error } = await useAPIFetch(`finance/deals/${dealId}/payments/invoices/${invoiceId}`, {
    method: 'delete',
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useUpdateDealPaymentsInvoiceStatus = async (dealId: number, invoiceId: number, statusId: number) => {
  const { data, error } = await useAPIFetch(`finance/deals/${dealId}/payments/invoices/${invoiceId}/status`, {
    method: 'patch',
    body: { status_id: statusId },
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useGetDealPaymentsIncoming = async (id: number) => {
  const { data, error } = await useAPIFetch(`finance/deals/${id}/payments/incoming`)
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useCreateDealPaymentsIncoming = async (dealId: number, invoice: FormData) => {
  const { data, error } = await useAPIFetch(`finance/deals/${dealId}/payments/incoming`, {
    method: 'post',
    body: invoice,
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useUpdateDealPaymentsIncoming = async (id: number, paymentId: number, payment: FormData) => {
  const { data, error } = await useAPIFetch(`finance/deals/${id}/payments/incoming/${paymentId}`, {
    method: 'patch',
    body: payment,
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useDeleteDealPaymentsIncoming = async (dealId: number, paymentId: number) => {
  const { data, error } = await useAPIFetch(`finance/deals/${dealId}/payments/incoming/${paymentId}`, {
    method: 'delete',
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useAddDealDeveloper = async (name: string) => {
  const { data, error } = await useAPIFetch('finance/developers', {
    method: 'post',
    body: { name },
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useAddDealProject = async (name: string) => {
  const { data, error } = await useAPIFetch('finance/projects', {
    method: 'post',
    body: { name },
  })
  if (error.value) throw createError(error.value)

  return data.value as ApiResponse
}

export const useGetDealHistory = async (id: number, page: number = 1) => {
  const { data, error } = await useAPIFetch<PaginationResponse>(`finance/deals/${id}/history`, { params: { page } })
  if (error.value) throw createError(error.value)

  return data.value
}

export const useUpdateDealTags = async (id: number, tagsIds: number[]) => {
  const { data, error } = await useAPIFetch<PaginationResponse>(`finance/deals/${id}/tags`, {
    method: 'put',
    body: { tag_ids: tagsIds },
  })
  if (error.value) throw createError(error.value)

  return data.value
}

export const useUpdateCommissionsGroup = async (id: number, groupId: number, statusId: number, note: string) =>
  await useAPIFetch(`finance/deals/${id}/commissions/groups/${groupId}`, {
    method: 'patch',
    body: { status_id: statusId, ...(note && { note }) },
  })

export const useUpdateDealVerificationStatus = async (id: number, statusId: number, note: string) =>
  await useAPIFetch(`finance/deals/${id}/verification-status/change`, {
    method: 'patch',
    body: { status: statusId, verification_note: note },
  })
